<template>
	<div class="components-preview">
		<div class="setting-bg"></div>
		<div class="left-panel">
			<div class="iframe-wrapper" :style="{width: $config.canvasH5Width + 'px', height: $config.canvasH5Height + 'px'}">
				<iframe :src="$config.PreviewURL + '/preview/' + previewId" frameborder="0" style="background-color:transparent;width: 100%; height: 100%;"></iframe>
			</div>
		</div>
		<div class="right-panel container-fluid">
			<div class="row pT-30">
				<div class="col-6">
					<p class="page-title paddingL20">{{ title }}</p>
				</div>
				<div class="col-6 text-right">
					<span class="cloase-btn pR-20" @click="closePreview">
						<i class="el-icon-close fs-22 cur-p"></i>
					</span>
				</div>
			</div>
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: "preview-page",
		props: {
			previewId: {},
			title: {type: String, default: ''}
		},
		data() {
			return {

			}
		},
		created() {

		},
		methods: {
			closePreview() {
				this.$emit('closePreview', false);
			}
		}
	}
</script>

<style lang="scss" scoped>
  .components-preview-inner {
    width: 100%;

  }

  .setting-bg{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.8);
    z-index: 1042;
  }
  .left-panel {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 640px;
    z-index: 1050;
    overflow: hidden;
  }
  .right-panel{
    position: fixed;
    width: 640px;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
    background-color: #fff;
    overflow-y: auto;
    color: #333;
  }
  .iframe-wrapper{
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -185px;
    margin-top: -295px;
    background: white;
  }
</style>
