<template>
	<div class="components-image-cropper">
		<p class="cropper-res-img-title">{{ title }}</p>
		<div class="inline-block cropper-res-img">
			<img :src="url || defaultCoverImage" alt="" />
			<el-upload action="https://jsonplaceholder.typicode.com/posts/" :before-upload="beforeUpload" accept="image/gif, image/jpeg, image/png, image/bmp" :show-file-list="false">
				<!--p class="cropper-res-img-title">Change the main picture</p-->
				<p class="cropper-res-img-title"><i class="fa fa-upload"></i></p>
			</el-upload>
		</div>
		<el-dialog title="Picture cropping" :visible.sync="dialogVisible" append-to-body center width="400px" id="picture-cropping">
			<div class="cropper-wrapper">
				<vueCropper ref="cropper" :img="option.img" :outputType="option.outputType" :autoCrop="option.autoCrop" :full="option.full"
				:autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" :fixedBox="option.fixedBox" :centerBox="option.centerBox" :info="option.info">
				</vueCropper>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false" size="small">Cancel</el-button>
				<el-button type="primary" @click="updateImage" size="small">Confirm</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {VueCropper} from 'vue-cropper'

	export default {
		components: {
			VueCropper
		},
		props: {
			url		: {type: String},
			title	: {type: String, default: 'Change picture'}
		},
		data() {
			return {
				loading: false,
				dialogVisible: false,
				defaultCoverImage: require('@/assets/Builder/images/template.png'),
				option: {
					img: '',
					outputSize: 1, //剪切后的图片质量（0.1-1）
					full: false,//输出原图比例截图 props名full
					autoCrop: true,
					outputType: 'png',
					// 只有自动截图开启 宽度高度才生效
					autoCropWidth: 120,
					autoCropHeight: 120,
					fixedBox: false,
					centerBox: false,
					info: false
				}
			}
		},
		methods: {
			updateImage() {
				this.loading = true;
				this.$refs.cropper.getCropBlob((data) => {
					let file = blobToFile(data)
					let params = new FormData()
					params.append('thumbnail', file);
					
					let that  	= this
					that.loading = true
					let url  	= '/page/thumbnail'
					
					let request = { url: url, method: 'POST', data: params }
					
					that.$store.dispatch('requests', request).then((response) => {
						
						that.loading = false
						if (response.data.status == 'success') {
							that.dialogVisible = false;
							that.$emit('update:url', response.data.thumbnail)
						} else {
							this.$message.error(response.data.message)
						}
						
					}).catch(({response}) => {
						that.loading = false
						that.ErrorHandler(response)
					} )
					
				})
			},
			/**
			 * 添加图片文件
			 * @param file
			 * @returns {boolean}
			 */
			beforeUpload(file) {
				if (file.size > 4 * 1024 * 1024) {
					this.$message.error('Upload pictures cannot exceed 4M！')
					return;
				}
				var reader = new FileReader();
				let _this = this;
				reader.onload = (e) => {
					let data;
					if (typeof e.target.result === 'object') {
						// 把Array Buffer转化为blob 如果是base64不需要
						data = window.URL.createObjectURL(new Blob([e.target.result]))
					}
					else {
						data = e.target.result
					}
					_this.option.img = data
				}
				// 转化为base64
				reader.readAsDataURL(file)
				this.dialogVisible = true;
				// 转化为blob
				// reader.readAsArrayBuffer(file);
				return false;
			}
		}
	}
	function blobToFile(blob, fileName){
		blob.lastModifiedDate =new Date();
		blob.name = fileName || new Date().getTime();
		return blob;
	}
</script>

<style lang="scss" scoped>
  .cropper-res-img {
    width: 100px;
    height: 100px;
    border: 1px dashed #2196f3;
    border-radius: 3px;
    margin-bottom: 18px;
    text-align: center;
    cursor: pointer;
    transition: all 0.28s;
    position: relative;
    background: #eee 50%/contain no-repeat;
    justify-content: center;
    align-items: center;
    display: flex;
	padding: 6px;
    &:hover {
      color: #2196f3;
    }
    .cropper-res-img-title {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 45px;
      transform: translateY(-50%);
      transform: translateX(-50%);
      text-align: center;
      background: rgba(0, 0, 0, 0.7);
      padding: 6px 8px;
      border-radius: 4px;
      color: #eee;
      transition: all 0.28s;
      &:hover {
        background: rgba(0, 0, 0, 0.8);
        color: white;
      }
    }
    img {
      display: inline-block;
      max-width: 100%;
      max-height: 100%;
    }
  }
	.cropper-wrapper{
		height: 400px;
	}
</style>
<style>
	#picture-cropping>.el-dialog {
		margin-top: 8vh !important;
	}
</style>
