<template>
  <el-dialog class="components-image-libs-wrapper" title="my picture" :visible.sync="dialogVisible" width="600px">
    <div class="components-image-libs">
      <div class="image-lib-side-bar"></div>
      <div class="image-lib-inner">
        <div class="image-lib-btn">
          <el-upload action="https://jsonplaceholder.typicode.com/posts/" :before-upload="beforeUpload" accept="image/jpeg,image/jpg,image/gif,image/png" :show-file-list="false">
            <el-button size="small" type="primary">Click upload</el-button>
            <span slot="tip" class="el-upload__tip marginL20">Only upload jpeg/jpg/png/gif files, and no more than 2M</span>
          </el-upload>
        </div>
        <ul class="image-list-wrapper" v-if="imageList.length">
          <li class="image-item" v-for="(item, index) in imageList" :key="index" @click="handleImageClick(item.url)">
            <img :src="item.url" alt="">
          </li>
        </ul>
        <div class="padding60 text-center gray" v-else>No data</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
	import $bus from '@/config/Builder/eventBus'

	export default {
		name: "image-libs",
		data() {
			return {
				dialogVisible: false,
				uploading: false,
				hasLoadData: false,
				imageList: [],
				selectId: ''
			}
		},
		created() {
			$bus.$on('show-select-image', selectId => {
				this.dialogVisible = true;
				this.selectId = selectId;
			})
		},
		watch: {
			dialogVisible(val) {
				if (val && !this.uploading) {
					this.getMyImages()
				}
			}
		},
		methods: {
			beforeUpload(file) {
				if (file.size > 1 * 1024 * 1024) {
					this.$message.error('psd file cannot exceed 1M！')
					return;
				}
				let temp1 = file.name.split('.')
				let temp = temp1[temp1.length - 1]
				if (!['jpeg', 'jpg', 'png', 'gif'].includes(temp)) {
					this.$message.error('Please upload jpg/png/gif file')
					return false;
				}
				this.uploadPsd(file);
				return false;
			},
			uploadPsd(file) {
				let params = new FormData()
				params.append('thumbnail', file);
				
				// Mouad
				let that  		= this
				that.uploading 	= true
				let url  		= '/page/thumbnail'
				
				let request = { url: url, method: 'POST', data: params }
				
				that.$store.dispatch('requests', request).then((response) => {
					
					that.uploading = false
					if (response.data.status == 'success') {
						// that.$message.success('Success..')
						// that.$emit('update:url', response.data.thumbnail)
						
						/* this.uploading = false;
						this.imageList.splice(0, 0, res.body) */
					} else {
						this.$message.error(response.data.message)
					}
					
				}).catch(({response}) => {
					that.uploading = false
					that.ErrorHandler(response)
				} )
				
			},
			getMyImages() {
				this.hasLoadData = true;
				
				// Mouad
				/* get('/person/images').then(res => {
					this.imageList = res.body || [];
				}) */
			},
			
			handleImageClick(url) {
				$bus.$emit('select-image', this.selectId, url)
				this.dialogVisible = false;
			}
		}
	}
</script>

<style lang="scss" scoped>
.image-list-wrapper{
  display: flex;
  height: 400px;
  padding-top: 20px;
  li{
    width: 120px;
    height: 120px;
    background: #eee 50%/contain no-repeat;
    cursor: pointer;
    justify-content:center;
    align-items:center;
    display: flex;
    transition: all 0.28s;
    &:hover{
      box-shadow: 0 0 16px 0 rgba(0,0,0,.16);
      transform: translate3d(0,-2px,0);
    }
    img{
      display: inline-block;
      max-width: 100%;
      max-height: 100%;
    }

  }
}
</style>
<style lang="scss">
  .components-image-libs-wrapper{
    .el-dialog__body{
      padding: 0 20px 20px;
    }
  }
</style>
